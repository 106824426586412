/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/15
* @description
*   FillInUp.vue of WeTrade
*/
<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FillInUp',
})
</script>
